import { useGoogleAuth } from "hooks";

import "./Header.scss";
import { Logo } from "assets/logo/GDrive-logo.svg"
import { useRecoilState, useResetRecoilState } from "recoil";
import { leftDrawerOpenState } from "state/leftDrawer/leftDrawer";
import { useCallback, useEffect, useState } from "react";
import { currentFileState } from "state/currentFile/currentFile";

type Props = {
  simpleView?: boolean;
}

export const Header: React.FC<Props> = ({ simpleView }) => {
  const [drawerOpen, setDraverOpen] = useRecoilState(leftDrawerOpenState);
  const resetFileState = useResetRecoilState(currentFileState);

  const { currentUser, requestGDAccess, logout } = useGoogleAuth();

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (showDropdown) {
      const handleClick = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        if (!target.closest(".Header__dropdown")) {
          setShowDropdown(false);
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [showDropdown]);

  const toggleDropdown = useCallback(e => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  }, [showDropdown]);

  const handleLogout = useCallback(() => {
    resetFileState();
    logout();
  }, [logout, resetFileState]);

  return (
    <header className="Header">
      <div className="Header__leftPart">
        {!simpleView &&
          <div
            onClick={() => setDraverOpen(!drawerOpen)}
            className="Header__drawer-toggle">
            <i className="material-symbols-outlined">
              {drawerOpen ? "menu_open" : "menu"}
            </i>
          </div>
        }

        <div className="Header__logoWrapper">
          <Logo style={{ color: "var(--white-color)" }} />
        </div>

        <div className="Header__buttons">

        </div>
      </div>

      {!simpleView &&
        <div className="Header__rightPart">
          {currentUser.googleAuthInitCredentials?.client_id && !currentUser.accessToGD &&
            <button
              onClick={requestGDAccess}
              className="Header__requestGDAccess">
              Request Access to Google Drive
            </button>
          }

          {currentUser.loggedIn && currentUser.userInfo?.picture &&
            <div
              title={currentUser.userInfo.email}
              onClick={toggleDropdown}
              className="Header__userIcon">
              <img
                crossOrigin="anonymous"
                src={currentUser.userInfo.pictureBase64 || currentUser.userInfo.picture}
                alt={currentUser.userInfo.email} />

            </div>
          }

          {showDropdown &&
            <div
              onClick={toggleDropdown}
              className="Header__dropdown">
              <div
                onClick={handleLogout}
                className="Header__dropdownItem">
                <span>Logout</span>
              </div>
            </div>
          }

          <div
            id="googleSignIn"
            style={{ display: currentUser.loggedIn && "none" }}
            className="Header__googleSignIn">
          </div>
        </div>
      }
    </header>
  );
}

import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useLocalStorage } from "hooks/localStorage/localStorage.hook";
import { log } from "services";
import { GDscopes } from "hooks/const";
import { urlToBase64 } from "services/urlToBase64/urlToBase64";
import { useRecoilState } from "recoil";
import { currentFileSelector } from "state/currentFile/currentFile";

const GoogleAuthContext = createContext(null);

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const currentUserDefaultState = {
  userInfo: null,
  loggedIn: false,
  accessToGD: false,
  googleAuthInitCredentials: null, // google.accounts.id.initialize
  googleAccessTokenToGD: null, // google.accounts.id.prompt
}

export const GoogleAuthProvider = ({ children }) => {
  const { items, setItem, getItem, removeItem } = useLocalStorage();
  const [currentFile] = useRecoilState(currentFileSelector);

  const [tabFocused, setTabFocused] = useState(true);

  const googleSignInRef = useRef(null);

  const currentUser = useMemo((): Partial<typeof currentUserDefaultState> => {
    return getItem('currentUser') || {};
  }, [getItem]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setTabFocused(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Initialize Google Auth and render Google Sign In button
  useEffect(() => {
    const currentUser = getItem('currentUser') || currentUserDefaultState;

    const googleAuthCallback = (googleAuthInitCredentials) => {
      let userInfo = null;

      currentUser.googleAuthInitCredentials = googleAuthInitCredentials;
      currentUser.loggedIn = true;

      try {
        userInfo = parseJwt(googleAuthInitCredentials.credential);

        console.log('userInfo', userInfo);
      } catch (error) {
        console.log('Error parsing user info', error);
      }

      if (userInfo?.picture) {
        urlToBase64(userInfo.picture)
          .then((base64) => {
            userInfo.pictureBase64 = base64;
            currentUser.userInfo = userInfo;

            setItem('currentUser', currentUser);
            console.log('googleAuthInitCredentials', currentUser);
          })
      } else {
        setItem('currentUser', currentUser);
        console.log('googleAuthInitCredentials', currentUser);
      }
    };

    const initTokenCallback = (googleAccessTokenToGD) => {
      googleAccessTokenToGD.receivedAt = new Date().toISOString();
      currentUser.googleAccessTokenToGD = googleAccessTokenToGD;
      currentUser.accessToGD = true;

      setItem('currentUser', currentUser);
      log.info('GoogleAuth: Access token to Google Drive received', currentUser);
    };

    const initGoogleAuth = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: googleAuthCallback,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("googleSignIn"),
        { size: "medium", type: "icon", shape: "circle", theme: "filled_black", }
      );

      googleSignInRef.current = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: GDscopes.join(' '),
        callback: initTokenCallback,
      });
    };

    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initGoogleAuth;
      document.body.appendChild(script);
    };

    loadGoogleScript();

  // Must be called only once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const refreshGDAccessToken = useCallback(() => {
  //   if (!currentUser.googleAccessTokenToGD?.receivedAt || !googleSignInRef.current || !tabFocused) {
  //     return;
  //   }

  //   const currentTokenReceivedAt = new Date(currentUser.googleAccessTokenToGD.receivedAt).getTime();
  //   const currentTime = new Date().getTime();
  //   const oneHour = 1000 * 60 * 45; // 45 minutes

  //   if (currentTime - currentTokenReceivedAt >= oneHour) {
  //     console.log('Requesting new access token');
  //     return googleSignInRef.current?.requestAccessToken({ prompt: 'none' });
  //   }
  // }, [currentUser.googleAccessTokenToGD, googleSignInRef, tabFocused]);

  // Request access token to access Google Drive
  // After 45 minutes request new token
  // useEffect(() => {
  //   refreshGDAccessToken();
  // }, [currentFile.fileChanged, googleSignInRef, currentUser]);

  // Request access token to access Google Drive manually
  const requestGDAccess = useCallback(() => {
    if (googleSignInRef.current) {
      googleSignInRef.current.requestAccessToken();
    }
  }, [googleSignInRef]);

  // https://developers.google.com/identity/sign-in/web/reference
  // const oldLogin = useCallback(() => {
  //   return new Promise((resolve, reject) => {
  //     window.gapi.auth2.getAuthInstance().signIn().then(oldLoginCredentials => {
  //       resolve(oldLoginCredentials);
  //       setItem('currentUser', { ...items.currentUser, oldLoginCredentials, loggedIn: true });
  //     }).catch((error) => {
  //       reject(error);
  //     });
  //   });
  // }, [setItem, items]);

  // const login = useCallback(async () => {

  // }, []);

  const logout = useCallback(() => {
    removeItem('userCredential');
    setItem('currentUser', { loggedIn: false, accessToGD: false });
  }, [removeItem, items, setItem]);

  const exports = {
    currentUser,
    requestGDAccess,
    // login,
    logout,
  }

  return (
    <GoogleAuthContext.Provider value={exports}>
      {children}
    </GoogleAuthContext.Provider>
  );
}

export const useGoogleAuth = () => {
  const context = useContext(GoogleAuthContext);

  if (!context) {
    throw new Error("useGoogleAuth must be used within a SingletonProvider");
  }

  return context;
}

import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { MimeTypesEnum } from "const/mimeTypes/mimeTypes.const";

import { log } from "services";
import { GDscopes } from "hooks/const";

import { GDFileDto } from "./dto/gapi.dto";
import { useGapiErrorHandler } from "./gapi-error-handler.hook";

const GapiContext = createContext(null);

export const GapiProvider = ({ children }) => {
  const [gapiCallbacks, setGapiCallbacks] = useState([]);
  const [gapiInitialized, setGapiInitialized] = useState(false);

  const { handleError } = useGapiErrorHandler();

  useEffect(() => {
    if (gapiInitialized && gapiCallbacks.length) {
      gapiCallbacks.forEach(cb => cb());
      setGapiCallbacks([]);
    }
  }, [gapiInitialized, gapiCallbacks, setGapiCallbacks]);

  const executeAfterGapiInit = useCallback((callback: () => void) => {
    if (gapiInitialized) {
      callback();
    } else {
      setGapiCallbacks([...gapiCallbacks, callback]);
    }
  }, [gapiInitialized, gapiCallbacks]);

  useEffect(() => {
    log.info('Initializing gapi...');

    const initGapi = () => {
      window.gapi.load('client:auth2', async () => {
        try {
          // Here is nothing to return
          await window.gapi.client.init({
            apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
            scope: GDscopes.join(' '),
            pluginName: 'gapi.auth2',
          });

          log.info('Gapi initialized');
          setGapiInitialized(true);
        } catch (error) {
          handleError(error);
        }
      });
    }

    const loadGapiScript = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.async = true;
      script.defer = true;
      script.onload = initGapi;
      document.body.appendChild(script);
    };

    loadGapiScript();
  // Must be loaded only once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGDListOf = useCallback((params: any): Promise<GDFileDto[]> => {
    return new Promise((resolve, reject) => {
      window.gapi.client.drive.files.list(params).then((response) => {
        const files = response.result.files
          .map(f => new GDFileDto(f))
          .sort((a, b) => {
            if (a.mimeType === MimeTypesEnum.Folder && b.mimeType !== MimeTypesEnum.Folder) return -1;
            if (a.mimeType !== MimeTypesEnum.Folder && b.mimeType === MimeTypesEnum.Folder) return 1;
            return 0;
          });

        resolve(files);
      }).catch(async (error) => {
        handleError(error);
        // reject(error);
      });
    });
  }, [handleError]);

  // Get file content
  const getGDFile = useCallback((fileInfo: GDFileDto): Promise<any> => {
    return new Promise((resolve, reject) => {
      const params = {
        fileId: fileInfo.id,
        alt: 'media',
        webContentLink: true,
      };

      window.gapi.client.drive.files.get(params).then((response) => {
        log.info('Downloaded file from GD:', response);
        resolve(response.body);
      }).catch((error) => {
        handleError(error, fileInfo.name);
        // reject(error);
      });
    });
  }, [handleError]);

  const getCurrentFileInfo = useCallback((fileInfo: GDFileDto): Promise<any> => {
    return new Promise((resolve, reject) => {
      const params = {
        fileId: fileInfo.id,
        fields: 'id, name, mimeType, parents, modifiedTime, createdTime, size',
      };

      window.gapi.client.drive.files.get(params).then((response) => {
        log.info('Downloaded file info from GD:', response);
        resolve(response.result);
      }).catch((error) => {
        handleError(error, fileInfo.name);
        // reject(error);
      });
    });
  }, [handleError]);

  // Update file content
  const updateGDFile = useCallback((fileInfo: GDFileDto, content: string): Promise<any> => {
    log.info(`useGapi: Update file...: ${fileInfo.name}`);

    return new Promise((resolve, reject) => {
      window.gapi.client.request({
        path: `https://www.googleapis.com/upload/drive/v3/files/${fileInfo.id}`,
        method: 'PATCH',
        params: {
          uploadType: 'media',
          fields: 'id, version, name',
        },
        body: content,
      }).then((response) => {
        log.info(`useGapi: File updated: ${fileInfo.name}`, response);
        resolve(response);
      }).catch((error) => {
        if (error.status === 401 && error.result?.error.message.includes('Request had invalid authentication credentials')) {
          // Experimantal fix for 401 error
          // initGapi().then(() => {
            // updateGDFile(fileInfo, content);
          // });
        }

        handleError(error, fileInfo.name);
        // reject(error);
      });
    });
  }, [handleError]);

  const exports = {
    gapiInitialized,
    getGDListOf,
    getGDFile,
    getCurrentFileInfo,
    updateGDFile,
    executeAfterGapiInit,
  }

  return (
    <GapiContext.Provider value={exports}>
      {children}
    </GapiContext.Provider>
  );
}

export const useGapi = () => {
  const context = useContext(GapiContext);

  if (!context) {
    throw new Error("useGapi must be used within a SingletonProvider");
  }

  return context;
}

import './Spinner.scss';

type Props = {
  size?: number | string;
  style?: React.CSSProperties;
}

export const Spinner: React.FC<Props> = ({ style, size }) => {
  return (
    <div
      style={style}
      className="Spinner">
      <div className="rotor">
        <i
          style={{ fontSize: size }}
          className="material-symbols-outlined">progress_activity</i>
      </div>
    </div>
  );
}

import { useCallback } from "react";

import { useRecoilState, useSetRecoilState } from "recoil";

import { allStatesSelector } from "state/allStates";

import { useLocalStorage } from "hooks/localStorage/localStorage.hook";
import { log } from "services";
import { useGapi } from "hooks/gapi";
import { currentFileSelector } from "state/currentFile/currentFile";
import { appLoadedAtom } from "state/appLoaded/appLoaded";

export const useAppState = () => {
  const [appState, setAppState] = useRecoilState(allStatesSelector);
  const setCurrentFile = useSetRecoilState(currentFileSelector);
  const setAppLoaded = useSetRecoilState(appLoadedAtom);

  const { executeAfterGapiInit, getCurrentFileInfo, getGDFile } = useGapi();

  const { setItem, getItem } = useLocalStorage();

  // Exported functions
  const saveAppState = useCallback(() => {
    log.info('Save app state to localstorage', appState);

    setItem('appState', appState);
  }, [appState, setItem]);

  const loadAppState = useCallback(async () => {
    const savedAppState = getItem('appState');

    if (savedAppState && Object.keys(savedAppState).length > 0) {
      log.info('Load app state from localstorage', savedAppState);

      setAppState(savedAppState);

      // If app reloads during file loading, we need to set fileLoaded to true
      if (savedAppState.currentFileState && savedAppState.currentFileState.fileLoaded === false) {
        savedAppState.currentFileState.fileLoaded = true;
      }

      if (savedAppState.currentFileState?.fileInfo) {
        executeAfterGapiInit(async () => {
          const fileInfo = await getCurrentFileInfo(savedAppState.currentFileState.fileInfo);
          log.info('Current file info from GD', fileInfo);

          const localFileLastUpdate = new Date(savedAppState.currentFileState.contentUpdatedLocalyAt).getTime();
          const gdFileLastUpdate = new Date(fileInfo.modifiedTime).getTime();

          if (gdFileLastUpdate > localFileLastUpdate) {
            setCurrentFile({ fileInfo, fileLoaded: false, contentFromGD: "", contentUpdatedLocaly: "" });
            const lastFile = await getGDFile(savedAppState.currentFileState.fileInfo);
            setCurrentFile({
              fileLoaded: true,
              fileChanged: false,
              contentFromGD: lastFile,
              contentUpdatedLocaly: lastFile,
            });
          }
        });
      }
    } else {
      log.info('No current file info in saved app state');
    }

    setAppLoaded(true);
  }, [getItem, executeAfterGapiInit, getCurrentFileInfo, getGDFile, setCurrentFile, setAppLoaded, setAppState]);

  return {
    saveAppState,
    loadAppState,
  }
}

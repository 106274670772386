import { DefaultValue, selector } from "recoil";

import { fileViewerState } from "./fileViewer/fileViewer";
import { currentFileState } from "./currentFile/currentFile";
import { leftDrawerOpenState } from "./leftDrawer/leftDrawer";

export const allStatesSelector = selector({
  key: 'allStates',
  get: ({ get }) => {
    return {
      fileViewerState: get(fileViewerState),
      currentFileState: get(currentFileState),
      leftDrawerOpenState: get(leftDrawerOpenState),
    };
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }

    set(currentFileState, newValue.currentFileState);
    set(fileViewerState, newValue.fileViewerState);
    set(leftDrawerOpenState, newValue.leftDrawerOpenState);
  }
});

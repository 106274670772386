import { ReactElement, useCallback, useEffect, useRef, useState } from "react";

import { useRecoilState } from "recoil";

import "./FileViewer.scss";
import { currentFileSelector } from "state/currentFile/currentFile";
import { useHotkeysHandlers } from "./hotkeys-handler";
// import { marked } from "marked";
import DOMPurify from "dompurify";
import { log } from "services";
import { useDebouncedCallback } from "use-debounce";
import { Loader } from "components/Loader/Loader";
import { useGoogleAuth } from "hooks";
import { WelcomePage } from "components/WelcomePage/WelcomePage";

const saveDebounceTime = 2000;

type MessageType = "info" | "success" | "error" | "warning" | "status";
type Message = { type: MessageType, title: string, textElement?: ReactElement };
type Props = {};

export const FileViewer: React.FC<Props> = () => {
  const [currentFile, setCurrentFile] = useRecoilState(currentFileSelector);

  const { currentUser } = useGoogleAuth();

  const [message, setMessage] = useState<Message | null>(null);

  const contentRef = useRef<HTMLPreElement>(null);

  const { saveFileToGD } = useHotkeysHandlers({ contentRef });

  // Update only if user opens file from explorer
  useEffect(() => {
    if (contentRef.current && currentFile) {
      log.info("FileViewer: File loaded. File ID:", currentFile.fileInfo?.id);

      const content = DOMPurify.sanitize(currentFile.contentUpdatedLocaly || "");
      contentRef.current.innerHTML = content;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef, currentFile, setCurrentFile]);

  const saveContentLocallyDebounced = useDebouncedCallback(() => {
    log.info("FileViewer: Saving content locally. File ID:", currentFile.fileInfo?.id);

    setCurrentFile({
      contentUpdatedLocaly: contentRef.current?.innerHTML,
      contentUpdatedLocalyAt: new Date().toISOString(),
      fileChanged: true,
    });
  }, saveDebounceTime);

  const onInputHandler = useCallback(() => {
    saveContentLocallyDebounced();

    if (!currentFile?.fileChanged) {
      setCurrentFile({ fileChanged: true });
    }
  }, [currentFile?.fileChanged, saveContentLocallyDebounced, setCurrentFile]);

  const onSaveFileToGD = useCallback(() => {
    saveContentLocallyDebounced.cancel();
    saveFileToGD();
  }, [saveFileToGD, saveContentLocallyDebounced]);

  useEffect(() => {
    if (!currentUser.loggedIn) {
      setMessage({
        type: "info",
        title: "Please login to see your Google Drive files.",
      });
      return;
    }

    if (!currentUser.accessToGD) {
      setMessage({ type: "info", title: "Provide access to Google Drive to operate." });
      return;
    }

    if (!currentFile.fileInfo) {
      setMessage({ type: "success", title: "Select a file to view or edit." });
      return;
    }

    if (currentFile.fileInfo) {
      setMessage({ type: "status", title: currentFile?.fileInfo?.name });
      return;
    }
  }, [currentUser.loggedIn, currentUser.accessToGD, currentFile]);

  return (
    <div className="FileViewer">
      <div className="FileViewer__infoBox">
        {message &&
          <div
            key={message.title}
            className={`FileViewer__message ${message.type}`}>
            {message.title}
            {message.textElement}
          </div>
        }
      </div>

      {!currentUser.loggedIn && <WelcomePage />}

      {currentUser?.loggedIn
        && (currentFile?.fileLoaded
          ? <pre
              ref={contentRef}
              onInput={onInputHandler}
              className="FileViewer__content"
              contentEditable={currentFile ? true : false}
              suppressContentEditableWarning>
            </pre>
          : <div style={{ width: "fit-content" }}>
              <Loader />
            </div>
        )
      }

      <div className="FileViewer__buttons">
        {currentUser?.loggedIn && currentUser.accessToGD && currentFile?.fileChanged &&
          <button
            onClick={onSaveFileToGD}
            className="FileViewer__buttonSave">
            Save
          </button>
        }
      </div>
    </div>
  );
}

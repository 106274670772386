import { MutableRefObject, useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";

import { useGapi } from "hooks";
import { log } from "services";
import { currentFileSelector } from "state/currentFile/currentFile";

type Props = {
  contentRef: MutableRefObject<HTMLPreElement | null>;
}

export const useHotkeysHandlers = ({ contentRef }: Props) => {
  const [currentFile, setCurrentFile] = useRecoilState(currentFileSelector);

  const { updateGDFile } = useGapi();

  const saveFileToGD = useCallback(() => {
    const current = contentRef?.current;
    const updatedContent = current?.innerHTML;

    if (contentRef.current && currentFile.fileInfo && updatedContent) {
      updateGDFile(currentFile.fileInfo, updatedContent);
      setCurrentFile({
        contentUpdatedLocaly: updatedContent,
        contentUpdatedLocalyAt: new Date().toISOString(),
        fileChanged: false,
      });
    }
  }, [contentRef, currentFile.fileInfo, updateGDFile, setCurrentFile]);

  // Ctrl + S | Save document
  const ctrlS = useCallback((e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === 's') {
      e.preventDefault();
      log.info('Ctrl + S pressed');
      saveFileToGD();
    }
  }, [saveFileToGD]);

  useEffect(() => {
    window.addEventListener('keydown', ctrlS);

    return () => {
      window.removeEventListener('keydown', ctrlS);
    }
  }, [ctrlS]);

  return {
    saveFileToGD,
  };
}

import { atom, selector } from "recoil";

const defaultState = {
}

export const fileViewerState = atom({
  key: 'fileViewerState',
  default: defaultState,
});

export const fileViewerSelector = selector({
  key: 'fileViewerSelector',
  get: ({ get }) => get(fileViewerState),
  set: ({ set, get }, newState: Partial<typeof defaultState>) => {
    set(fileViewerState, newState);
  }
});

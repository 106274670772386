export enum MimeTypesEnum {
  ApplicationGroup = 'application',

  File = 'application/vnd.google-apps.file',
  Form = 'application/vnd.google-apps.form',
  Sheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Folder = 'application/vnd.google-apps.folder',
  Jgraph = 'application/vnd.jgraph.mxfile',
  Drawing = 'application/vnd.google-apps.drawing',
  Document = 'application/vnd.google-apps.document',
  Spreadsheet = 'application/vnd.google-apps.spreadsheet',
  Presentation = 'application/vnd.google-apps.presentation',


  Odt = 'application/vnd.oasis.opendocument.text',
  Ods = 'application/vnd.oasis.opendocument.spreadsheet',
  Odp = 'application/vnd.oasis.opendocument.presentation',

  Pdf = 'application/pdf',
  Zip = 'application/zip',
  Rtf = 'application/rtf',
  Json = 'application/json',
  Epub = 'application/epub+zip',

  Gif = 'image/gif',
  Png = 'image/png',
  Bmp = 'image/bmp',
  Svg = 'image/svg+xml',
  Webp = 'image/webp',
  Image = 'image/jpeg',

  Wav = 'audio/wav',
  Flac = 'audio/flac',
  Weba = 'audio/webm',
  Audio = 'audio/mpeg',

  Ogg = 'video/ogg',
  Avi = 'video/x-msvideo',
  Webm = 'video/webm',
  Mpeg = 'video/mpeg',
  Video = 'video/mp4',

  Xml = 'text/xml',
  Csv = 'text/csv',
  Html = 'text/html',
  Text = 'text/plain',
}

import { createContext, useCallback, useContext, useState } from "react";

import { storagePrefix } from "./storagePrefix";
import { getLocalstorageState } from "./getLocalstorageState";

export const LocalStorageContext = createContext(null);

export const LocalStorageProvider = ({ children }) => {
  const [items, setItems] = useState<Record<string, any>>(getLocalstorageState());

  const setItem = useCallback((keyOrObject: string | Record<string, any>, value: any) => {
    if (typeof keyOrObject === 'object') {
      let newState = {};

      Object.keys(keyOrObject).forEach((k) => {
        newState = {...newState, [k]: keyOrObject[k]};
        localStorage.setItem(storagePrefix + k, JSON.stringify(keyOrObject[k]));
      });

      setItems((state) => ({...state, ...newState}));
    } else {
      setItems((state) => ({...state, [keyOrObject]: value}));
      localStorage.setItem(storagePrefix + keyOrObject, JSON.stringify(value));
    }
  }, []);

  const getItem = useCallback((key: string) => {
    return items[key];
  }, [items]);

  const removeItem = useCallback((key: string) => {
    setItems((state) => {
      const newState = {...state};
      delete newState[key];
      return newState;
    });

    localStorage.removeItem(storagePrefix + key);
  }, []);

  return (
    <LocalStorageContext.Provider value={{ items, setItem, getItem, removeItem }}>
      { children }
    </LocalStorageContext.Provider>
  )
}

export const useLocalStorage = () => {
  return useContext(LocalStorageContext);
}

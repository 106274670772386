import './WelcomePage.scss';

export const WelcomePage: React.FC = () => {
  return (
    <div className="WelcomePage">
      <h1>Welcome to GDrive notes</h1>
      <p>
        The purpose of the Application is to provide users an secure platform to interact with
        their Google Drive files,<br/> specifically focusing on managing and editing text files. The app is designed to:
      </p>

      <ol>
        <li>
          <b>Streamline File Operations</b><br/>
          Allow users to view, create, edit, and organize text files directly within their Google Drive in an efficient and user-friendly interface.
        </li>
        <li>
          <b>Enhance Productivity</b><br/>
          Provide tools to manage notes, documents, and other text-based files, making it easier for users to work on their files collaboratively or independently.
        </li>
        <li>
          <b>Ensure Data Security</b><br/>
          Operate within the Google API's security framework, ensuring that all user data is accessed only with explicit consent and handled securely.
        </li>
        <li>
          <b>Offer Seamless Integration</b><br/>
          Enable smooth integration with Google Drive so users can access their files from anywhere, on any device, without needing additional software or tools.
        </li>
        <li>
          <b>Focus on Simplicity</b><br/>
          Deliver a minimalistic and straightforward interface to eliminate complexity in managing text files.
        </li>
      </ol>

      <p>
        To start using the app, please sign in with your Google account.
      </p>
    </div>
  );
}

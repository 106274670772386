import { atom, selector } from "recoil";

import { GDFileDto } from "hooks/gapi/dto/gapi.dto";

export const defaultFileState = {
  fileInfo: null as GDFileDto,
  fileLoaded: true,
  fileChanged: false,
  contentFromGD: null,
  contentUpdatedLocaly: null,
  contentUpdatedLocalyAt: null,
  error: null,
}

export const currentFileState = atom({
  key: 'currentFileState',
  default: defaultFileState,
});

export const currentFileSelector = selector({
  key: 'currentFileSelector',
  get: ({ get }) => get(currentFileState),
  set: ({ set, get }, newState: Partial<typeof defaultFileState>) => {
    const currentState = get(currentFileState);
    const nextState = {
      ...currentState,
      ...newState,
    }

    set(currentFileState, nextState);
  },
});

import { log } from "services";

import { GapiError } from "./dto/error.dto";
import { GoogleAuthError } from "hooks/googleAuth/dto/error.dto";
import { useCallback } from "react";
import { useGoogleAuth } from "hooks/googleAuth/googleAuth.hook";
import { useLocalStorage } from "hooks/localStorage/localStorage.hook";

export const useGapiErrorHandler = () => {
  const { setItem } = useLocalStorage();
  const { currentUser } = useGoogleAuth();

  const checkGDAccessTocken = useCallback(() => {
    if (!currentUser.googleAccessTokenToGD?.receivedAt) {
      return;
    }

    const currentTokenReceivedAt = new Date(currentUser.googleAccessTokenToGD?.receivedAt).getTime();
    const currentTime = new Date().getTime();
    const oneHour = 1000 * 60 * 60; // 1 hour

    if (currentTime - currentTokenReceivedAt >= oneHour) {
      currentUser.accessToGD = false;

      setItem('currentUser',  currentUser);
    }
  }, [currentUser, setItem]);

  const handleError = useCallback((error: GapiError & GoogleAuthError, message?: string) => {
    const errorMessage = error.result?.error?.message || error.error;
    const errorDetails = error.body || error.details;

    checkGDAccessTocken();

    if (errorMessage && errorDetails) {
      if (message) {
        log.error(message, '|', errorMessage, '|', errorDetails, error);
      } else {
        log.error(errorMessage, '|', errorDetails, error);
      }
    } else {
      log.error(error);
    }
  }, [checkGDAccessTocken]);

  return {
    handleError,
  }
}

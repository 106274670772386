import { MimeTypesEnum } from "const/mimeTypes/mimeTypes.const";
import { GDFileDto } from "hooks/gapi/dto/gapi.dto";

import "./ListItem.scss";
import { useCallback, useState } from "react";
import { Spinner } from "components/Spinner/Spinner";
import { useGapi } from "hooks";
import { getChildrenParams } from "hooks/gapi";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { currentFileSelector } from "state/currentFile/currentFile";

type Props = {
  file: GDFileDto;
  children?: React.ReactNode;
};

export const ListItem: React.FC<Props> = ({ file, children }) => {
  const [open, setOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [childrenElements, setChildrenElements] = useState(children);

  const setCurrentFile = useSetRecoilState(currentFileSelector);
  const resetCurrentFile = useResetRecoilState(currentFileSelector);

  const { getGDListOf, getGDFile } = useGapi();

  const getChildren = useCallback(async () => {
    setInProgress(true);

    const children = await getGDListOf(getChildrenParams(file.id));

    setInProgress(false);

    setChildrenElements(children.map(c => <ListItem
      key={c.id}
      file={c} />
    ));
  }, [file.id, getGDListOf]);

  const getFile = useCallback(async () => {
    resetCurrentFile();
    setCurrentFile({ fileInfo: file, fileLoaded: false });
    setInProgress(true);

    const GDFile = await getGDFile(file);

    setCurrentFile({ fileLoaded: true, contentFromGD: GDFile, contentUpdatedLocaly: GDFile });
    setInProgress(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.id, getGDFile, setInProgress, setCurrentFile]);

  const toggleItem = useCallback(() => {
    if (file.mimeType === MimeTypesEnum.Folder) {
      if (!open) {
        getChildren();
      }

      setOpen(!open);
    } else {
      getFile();
    }
  }, [file.mimeType, open, getChildren, getFile]);

  return <div className="ListItem">
    <div
      className="ListItem__content"
      onClick={() => toggleItem()}>

      {file &&
        <div className="ListItem__leftPart">
          <div className="ListItem__expand">
            {file.mimeType === MimeTypesEnum.Folder &&
              <i className="material-symbols-outlined inline">{open ? "expand_more" : "chevron_right"}</i>
            }
          </div>

          <div className="ListItem__icon">
            <i className="material-symbols-outlined inline">{file.mimeType === MimeTypesEnum.Folder && "folder"}</i>
            <i className="material-symbols-outlined inline">{file.mimeType === MimeTypesEnum.Text && "description"}</i>
            <i className="material-symbols-outlined inline">
              {file.mimeType !== MimeTypesEnum.Folder && file.mimeType.includes(MimeTypesEnum.ApplicationGroup) && "description"}
            </i>
          </div>

          <div className="ListItem__name">
            {file.name}
          </div>
        </div>
      }

      <div className="ListItem__rightPart">
        {inProgress && <Spinner size='1rem' />}
      </div>
    </div>

    {open && <div className="ListItem__children">
      {childrenElements}
    </div>}
  </div>
};

import { MimeTypesEnum } from "const/mimeTypes/mimeTypes.const";

const fields = 'files(id, name, parents, mimeType, modifiedTime)';

const queries = {
  allFromRoot: `'root' in parents`,
  folders: `mimeType='${MimeTypesEnum.Folder}'`,
  files: `mimeType!='${MimeTypesEnum.Folder}'`,
  children: `'{{folderId}}' in parents`,
};

export const getAllFromRootParams = { q: queries.allFromRoot, fields };

export const getFoldersParams = { q: queries.folders, fields };

export const getFilesParams = { q: queries.files, fields };

export const getChildrenParams = (folderId: string) => ({
  q: queries.children.replace('{{folderId}}', folderId),
  fields,
});

import { useCallback, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { GDFileDto } from 'hooks/gapi/dto/gapi.dto';
import { useAppState } from 'hooks/appState/appState.hook';
import { getAllFromRootParams } from 'hooks/gapi';
import { useGoogleAuth, useGapi } from 'hooks';

import './App.scss';

import { Header } from 'components/Header/Header';
import { LeftDrawer } from 'components/LeftDrawer/LeftDrawer';
import { FileViewer } from 'components/FileViewer/FileViewer';
import { appLoadedAtom } from 'state/appLoaded/appLoaded';
import { Loader } from 'components/Loader/Loader';
import { log } from 'services';

export const App = () => {
  const { saveAppState, loadAppState } = useAppState();
  const appLoaded = useRecoilValue(appLoadedAtom);

  const [inProgress, setInProgress] = useState(false);
  const [GDEntities, setGDEntities] = useState<GDFileDto[]>([]);

  const { gapiInitialized, getGDListOf } = useGapi();
  const { currentUser } = useGoogleAuth();

  useEffect(() => {
    loadAppState();
  // Must be loaded only once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAppStateCallback = useCallback(() => {
    log('App: Saving app state before unload');
    saveAppState();
  }, [saveAppState]);

  useEffect(() => {
    window.removeEventListener('beforeunload', saveAppStateCallback);
    window.removeEventListener('blur', saveAppStateCallback);
    window.addEventListener('beforeunload', saveAppStateCallback);
    window.addEventListener('blur', saveAppStateCallback);

    return () => {
      window.removeEventListener('beforeunload', saveAppStateCallback);
      window.removeEventListener('blur', saveAppStateCallback);
    };
  }, [saveAppStateCallback]);

  const getRootFilesList = useCallback(async () => {
    log.info('App: Getting root files list');
    setInProgress(true);

    const rootEntities =  await getGDListOf(getAllFromRootParams);

    // Filter out duplicates
    setGDEntities(files => {
      const allUniqueEntities = new Map<string, GDFileDto>();

      [...files, ...rootEntities].forEach(e => allUniqueEntities.set(e.id, e));
      const allEntitiesArray = Array.from(allUniqueEntities.values());

      return allEntitiesArray;
    });

    setInProgress(false);
  }, [getGDListOf, setGDEntities]);

  useEffect(() => {
    log.info('App: gapiInitialized, userLoggedIn, accesToGoogleDriveGranted', gapiInitialized, currentUser.loggedIn, currentUser.accessToGD);

    if (gapiInitialized && currentUser.loggedIn && currentUser.accessToGD) {
      getRootFilesList();
    }
  }, [gapiInitialized, currentUser.loggedIn, currentUser.accessToGD, getRootFilesList]);

  return (
    <div className="App">
      {appLoaded
        ? <>
            <Header />

            <div className="Workspace">
              <LeftDrawer
                files={GDEntities}
                inProgress={inProgress} />

              <FileViewer />
            </div>
          </>
        : <Loader />
      }
    </div>
  );
}

export default App;

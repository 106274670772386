import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { GapiProvider, GoogleAuthProvider, LocalStorageProvider } from 'hooks';

import reportWebVitals from './reportWebVitals';
import 'assets/scss/index.scss';
import { router } from 'pages/router';
import { RouterProvider } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <LocalStorageProvider>
      <GoogleAuthProvider>
        <GapiProvider>
          <RouterProvider router={router} />
        </GapiProvider>
      </GoogleAuthProvider>
    </LocalStorageProvider>
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

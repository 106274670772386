import { FC } from "react";

type Props = {
  style?: { [key: string]: string; }
}

export const Logo: FC<Props> = ({ style }) => {
  return <div style={style} className="logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.0"
      viewBox="0 0 985.000000 373.000000"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,373.000000) scale(0.100000,-0.100000)"
      fill={style?.color || '#000'} stroke="none">
      <path d="M2680 3719 c-31 -12 -97 -75 -114 -109 -8 -14 -16 -42 -20 -62 -6
      -33 -11 -38 -44 -44 -52 -9 -71 -20 -123 -71 l-46 -45 -21 27 c-46 58 -136 80
      -207 51 -32 -14 -125 -99 -125 -115 0 -5 -73 -11 -162 -14 -384 -14 -717 -142
      -1011 -387 -273 -228 -492 -588 -557 -915 -11 -57 -16 -65 -115 -165 -58 -58
      -111 -122 -120 -142 -12 -31 -15 -91 -15 -343 0 -330 1 -339 56 -404 47 -56
      92 -71 222 -71 l113 0 17 -31 c9 -17 47 -77 84 -133 409 -623 1198 -895 1916
      -660 389 127 736 414 933 770 33 60 44 74 58 68 28 -14 392 -17 435 -4 23 7
      63 34 97 66 l57 55 21 -34 c11 -19 40 -47 63 -63 l41 -29 401 -3 402 -3 44 30
      c25 16 100 85 166 153 117 118 122 125 134 181 12 62 2 137 -25 173 -11 15 -9
      24 9 58 18 35 21 58 21 151 -1 122 -13 161 -68 215 -61 60 -74 61 -489 58
      -268 -2 -380 -6 -389 -15 -8 -8 -19 -8 -45 3 -24 10 -78 14 -192 14 -138 0
      -163 -3 -197 -20 -37 -19 -40 -19 -64 -2 -20 13 -55 18 -155 22 l-129 5 -32
      130 c-53 211 -122 364 -244 548 -80 119 -234 285 -340 367 -58 44 -70 59 -65
      75 3 11 11 40 17 64 6 24 16 44 21 46 80 21 103 31 137 62 50 45 69 81 69 130
      0 76 -61 136 -152 148 -55 8 -63 17 -73 88 -15 100 -108 160 -195 126z m92
      -189 c22 -91 44 -114 128 -131 118 -23 121 -58 7 -83 -93 -21 -111 -40 -135
      -137 -17 -66 -21 -74 -42 -74 -21 0 -26 7 -36 57 -23 115 -44 137 -155 158
      -48 9 -68 22 -69 42 0 12 32 25 94 38 80 16 108 45 126 130 16 80 17 82 43 78
      17 -2 24 -17 39 -78z m-549 -188 c8 -10 22 -57 31 -107 24 -127 54 -211 87
      -250 39 -44 111 -73 248 -101 123 -25 151 -41 151 -85 0 -40 -27 -54 -148 -79
      -131 -26 -204 -54 -241 -91 -38 -37 -68 -117 -95 -250 -11 -58 -27 -113 -35
      -123 -20 -24 -76 -21 -95 6 -9 12 -16 32 -16 44 0 49 -61 259 -85 296 -39 58
      -145 100 -338 133 -68 11 -100 71 -58 109 11 10 69 27 135 41 64 13 144 36
      179 52 52 25 66 37 89 79 26 48 59 163 73 254 9 57 21 79 48 89 27 10 52 4 70
      -17z m-867 -426 c16 -61 22 -67 87 -86 66 -20 67 -40 3 -56 -61 -16 -74 -29
      -90 -90 -7 -29 -18 -49 -26 -49 -8 0 -19 20 -26 49 -16 61 -29 74 -90 90 -64
      16 -64 36 1 54 65 18 70 23 85 80 7 26 15 51 17 55 11 19 28 -3 39 -47z m-199
      -968 c11 -12 18 -39 21 -82 4 -59 2 -67 -21 -90 l-26 -26 -194 0 c-154 0 -197
      -3 -206 -14 -8 -9 -11 -56 -9 -142 l3 -129 30 0 30 0 6 72 c9 120 57 149 227
      140 89 -4 96 -6 125 -36 l32 -31 3 -139 c3 -107 0 -146 -12 -175 -27 -65 -41
      -67 -426 -64 l-340 3 -32 33 -33 32 0 299 0 299 25 27 c14 15 34 31 45 36 11
      4 181 7 377 6 324 -2 359 -4 375 -19z m803 -2 c49 -26 79 -55 103 -101 21 -37
      22 -54 22 -245 l0 -205 -28 -48 c-32 -54 -82 -91 -147 -107 -29 -7 -143 -10
      -322 -8 l-278 3 -32 33 -33 32 0 299 0 299 25 27 c14 15 34 31 45 36 11 4 153
      7 315 6 266 -2 298 -4 330 -21z m1418 12 c18 -18 14 -46 -7 -58 -11 -5 -86
      -10 -171 -10 -155 0 -190 7 -190 38 0 38 19 42 190 42 116 0 170 -4 178 -12z
      m-451 -160 c31 -29 33 -108 3 -138 -18 -18 -33 -20 -189 -20 -124 0 -171 -3
      -179 -13 -6 -7 -12 -80 -14 -169 l-3 -158 -33 -32 c-32 -33 -34 -33 -140 -36
      -134 -4 -175 9 -198 64 -12 29 -15 76 -12 251 l3 215 33 29 32 29 337 0 c332
      0 337 0 360 -22z m429 -12 l34 -34 0 -225 c0 -211 -1 -226 -21 -250 -29 -38
      -80 -50 -198 -45 -102 3 -103 3 -132 36 l-29 32 0 229 0 229 32 31 32 31 124
      0 124 0 34 -34z m418 16 c9 -11 29 -57 45 -103 32 -95 28 -97 72 30 32 91 31
      91 195 91 142 0 143 0 163 -26 12 -15 21 -35 21 -45 0 -10 -45 -120 -101 -246
      -73 -165 -108 -233 -127 -248 -24 -18 -45 -20 -182 -23 -221 -4 -200 -21 -327
      264 -56 126 -103 241 -103 255 0 14 9 35 20 47 20 21 30 22 164 22 126 0 145
      -2 160 -18z m1342 -16 c34 -34 34 -34 34 -131 0 -97 0 -97 -34 -131 l-34 -34
      -186 0 c-170 0 -186 -1 -195 -19 -7 -13 -7 -23 2 -34 10 -14 43 -17 210 -19
      195 -3 199 -3 218 -27 25 -31 25 -101 0 -132 l-19 -24 -344 -3 c-368 -3 -402
      0 -437 45 -20 24 -21 39 -21 244 0 134 4 227 11 242 25 55 34 56 412 57 l349
      0 34 -34z"/>
      <path d="M1635 1878 c-3 -7 -4 -137 -3 -288 l3 -275 30 0 30 0 0 285 0 285
      -28 3 c-16 2 -29 -2 -32 -10z"/>
      <path d="M4706 1734 c-11 -10 -16 -34 -16 -74 0 -76 6 -90 40 -90 34 0 40 14
      40 90 0 56 -15 90 -40 90 -5 0 -16 -7 -24 -16z"/>
      <path d="M5708 2066 c-48 -17 -108 -68 -240 -203 -78 -81 -109 -120 -117 -149
      -7 -24 -11 -153 -11 -335 l0 -297 28 -53 c17 -34 44 -65 71 -84 42 -30 44 -30
      197 -33 147 -3 157 -2 197 20 l43 24 43 -23 c40 -21 57 -23 186 -23 127 0 146
      2 186 23 l44 22 45 -22 c44 -23 50 -23 415 -23 347 0 373 2 410 20 22 11 47
      25 56 31 13 10 23 7 54 -17 l38 -29 231 1 c127 0 245 1 261 0 28 0 797 1 848
      2 12 0 190 -2 395 -4 337 -4 376 -3 410 12 20 10 103 83 184 163 170 169 172
      174 166 337 -2 76 -8 107 -22 128 -17 27 -17 29 3 76 35 81 23 188 -27 243 -9
      11 -34 28 -55 39 -36 18 -58 19 -405 16 -329 -3 -371 -5 -394 -20 -25 -17 -29
      -17 -70 2 -40 18 -67 20 -403 20 -198 0 -375 -4 -393 -9 -31 -9 -33 -8 -51 27
      -25 48 -77 97 -123 116 -44 19 -260 22 -321 5 -24 -6 -63 -34 -106 -75 l-69
      -64 -392 0 -392 0 -28 43 c-31 48 -67 78 -119 95 -51 18 -724 16 -773 -2z
      m747 -110 c62 -26 65 -42 65 -358 0 -263 -1 -285 -20 -315 -26 -44 -73 -56
      -199 -51 -102 3 -103 3 -132 36 l-29 32 0 276 c0 171 -4 283 -10 295 -13 23
      -55 25 -64 3 -3 -9 -6 -138 -6 -287 0 -300 -2 -310 -60 -340 -29 -15 -55 -17
      -148 -15 l-114 3 -29 33 -29 32 0 296 c0 190 4 302 11 315 5 11 25 29 42 39
      30 19 53 20 360 20 261 0 335 -3 362 -14z m1440 -16 c14 -14 31 -43 37 -66 9
      -32 19 -45 42 -55 40 -18 40 -47 1 -69 l-30 -17 0 -208 0 -209 24 -6 c33 -8
      49 -35 35 -57 -10 -16 -32 -18 -175 -21 -191 -4 -231 4 -259 51 -18 29 -20 51
      -20 238 0 216 -1 222 -45 231 -15 2 -20 11 -20 33 0 22 5 31 20 33 29 6 45 21
      45 44 0 34 30 79 63 94 23 10 64 13 144 12 103 -3 113 -5 138 -28z m-493 -149
      l33 -29 3 -221 c3 -187 1 -225 -13 -251 -30 -57 -50 -60 -412 -60 -353 0 -358
      1 -397 53 -19 26 -21 44 -24 210 -4 226 0 262 40 299 l30 28 354 0 354 0 32
      -29z m1455 -6 l35 -35 -4 -100 c-3 -93 -5 -101 -31 -127 l-27 -28 -189 -3
      c-155 -3 -192 -6 -202 -18 -9 -11 -10 -20 -2 -32 9 -15 38 -18 212 -22 198 -5
      203 -6 222 -29 42 -51 17 -141 -43 -156 -13 -3 -180 -5 -371 -3 l-349 3 -29
      33 -29 32 0 231 0 231 31 29 31 29 355 0 355 0 35 -35z m858 15 c26 -29 26
      -111 1 -139 -18 -20 -29 -21 -187 -21 -139 0 -170 -3 -183 -16 -18 -18 -18
      -17 -10 -38 5 -14 28 -16 148 -17 222 0 251 -20 251 -169 0 -74 -3 -93 -21
      -117 -39 -52 -45 -53 -390 -53 -310 0 -322 1 -348 21 -22 18 -26 28 -26 74 0
      46 4 56 26 74 25 19 40 21 189 21 136 0 165 3 176 16 9 11 10 20 2 32 -8 14
      -35 18 -172 22 l-163 5 -29 33 c-28 31 -29 36 -29 133 0 100 0 101 31 130 l31
      29 343 0 c331 0 343 -1 360 -20z"/>
      <path d="M6992 1738 c-14 -14 -17 -395 -3 -416 4 -8 19 -12 32 -10 l24 3 3
      204 c2 203 -2 231 -32 231 -7 0 -17 -5 -24 -12z"/>
      <path d="M8446 1734 c-16 -16 -23 -115 -10 -148 9 -23 51 -20 65 5 15 29 7
      138 -12 150 -19 12 -25 11 -43 -7z"/>
      </g>
    </svg>
  </div>
}

const originalConsole = console;

// Define custom colors for each log level
const colors = {
  trace: 'color: purple',
  debug: 'color: blue',
  info: 'color: green',
  warn: 'color: orange',
  error: 'color: red; font-weight: bold',
  // ===
  time: 'color: gray',
  log: 'color: #87ffef', // light blue
};

const getMethod = (name: string) => {
  return (message: string, ...args: any[]) => {
    const currentTime = new Date().toISOString();
    const mesageIsString = typeof message === 'string';

    if (mesageIsString && message.startsWith('%c')) {
      return originalConsole.info.apply(
        originalConsole,
        [
          `%c[${name.toLocaleUpperCase()}] %c[${currentTime}]%c ${message}`,
          colors[name], colors.time, colors.log,
          ...args
        ]
      );
    } else {
      return originalConsole.info.apply(
        originalConsole,
        [
          `%c[${name.toLocaleUpperCase()}] %c[${currentTime}]%c`,
          colors[name], colors.time, colors.log,
          message,
          ...args
        ]
      );
    }
  };
}

interface LogMethod {
  (message: any, ...args: any[]): void;
  trace?: LogMethod;
  debug?: LogMethod;
  info?: LogMethod;
  warn?: LogMethod;
  error?: LogMethod;
}

const log: LogMethod = getMethod('log');

log.trace = getMethod('trace');
log.debug = getMethod('debug');
log.info = getMethod('info');
log.warn = getMethod('warn');
log.error = getMethod('error');

export { log };

import { GDFileDto } from 'hooks/gapi/dto/gapi.dto';

import { Spinner } from 'components/Spinner/Spinner';
import { ListItem } from '../ExplorerListItem/ListItem';

import './LeftDrawer.scss';
import { useRecoilState } from 'recoil';
import { leftDrawerOpenState } from 'state/leftDrawer/leftDrawer';
import { Link } from 'react-router-dom';

type Props = {
  files: GDFileDto[];
  inProgress: boolean;
};

export const LeftDrawer: React.FC<Props> = ({ inProgress, files }) => {
  const [drawerOpen] = useRecoilState(leftDrawerOpenState);

  return (
    <div className={`LeftDrawer ${drawerOpen ? '' : 'closed'}`}>
      <h2 className="LeftDrawer__title">
        Explorer
      </h2>

      {inProgress && <Spinner />}

      {files.map(f => <ListItem
        key={f.id}
        file={f} />)}

      <div className='LeftDrawer__bottom'>
        <Link to='/policy'>Privacy Policy</Link>
        <Link to='/terms-of-service'>Terms of Service</Link>
      </div>
    </div>
  );
};

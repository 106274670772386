export class GDFileDto {
  id: string;
  name: string;
  mimeType: string;
  modifiedTime: string;
  parents: string[];

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.mimeType = data.mimeType;
    this.modifiedTime = data.modifiedTime;
    this.parents = data.parents;
  }
};

import { FC } from "react";
import "./Loader.scss";

type Props = {

}

export const Loader: FC<Props> = () => {
  return (
    <div className="Loader__containter">
      <div className="Loader"></div>
    </div>
  )
}
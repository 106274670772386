import { createBrowserRouter } from "react-router-dom";

import App from "./App";
import { Privacy } from "./Privacy/Privacy";
import { TermsOfService } from "./TermsOfService/TermsOfService";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/policy",
    element: <Privacy />,
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />
  }
]);
